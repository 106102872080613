import React from 'react';
import Box from './Box';
import image1 from '../images/s1.png';
import image2 from '../images/s2.png';
import snakeImage from '../images/snakeImage.jpg';
import oculusButtonImage from '../images/availableOnOculus.png';
import discordButtonImage from '../images/discord-button.png';

function Services() {
    return (
        <div id='services'>
         <div className='s-heading'>
             <br/><br/><br/>
             <h1>Popular Apps</h1>
                {/* <h1>Features</h1> */}
                {/* <p>Check out some of our most popular apps!</p> */}
            </div>
            <div className='b-container'>
                <Box 
                image={snakeImage}
                alte='snakeImage'
                regularButton={null}
                specialButton1 = {oculusButtonImage}
                specialButton2 = {discordButtonImage}
                link2="https://discord.gg/Ys6VuBgS"
                link1="https://www.oculus.com/experiences/quest/5712013818895184/?store&item_id=5712013818895184"
                title=""
                details=""
                />
                 <div class="text-overlay">
                <p>Snakes LTD</p>
                </div> 
                
            </div>
        </div>
    )
}

export default Services;
